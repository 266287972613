<template>
  <div class="md:mx-44 m-8">
    <ol
      v-for="(faq, index) in FAQs"
      :key="index"
      class="text-2xl font-semibold"
    >
      <li class="mt-4">
        {{ index + 1 }}.
        {{ faq.title }}
        <div
          class="flex flex-col gap-2 mt-4 ml-6"
          v-for="(data, dataIndex) in faq.data"
          :key="dataIndex"
        >
          <div
            class="flex flex-row gap-4 items-center font-medium text-lg hover:text-green-1 cursor-pointer"
            @click="toggleQuestion(index, dataIndex)"
            :class="[data.show ? 'text-green-1' : '']"
          >
            <font-awesome-icon
              icon="fa-solid fa-circle-chevron-up"
              v-if="data.show"
            />
            <font-awesome-icon icon="fa-solid fa-circle-chevron-down" v-else />
            <p>{{ data.question }}</p>
          </div>
          <div v-html="data.answer" v-if="data.show"></div>
        </div>
      </li>
    </ol>
  </div>
</template>
<script>
export default {
  name: "TripsFAQ",
  data() {
    return {
      FAQs: [
        {
          title: "HOW TO BOOK",
          data: [
            {
              show: false,
              question: "What Attracions are available on Tripwe?",
              answer: `<div class="flex flex-col ml-10">
                        <p class="text-base font-normal">
                        Tripwe offers 3 types of adventures: water adventure, land adventure, and
                        sky adventure.
                        </p>
                        <ol class="list-disc ml-10 text-base font-normal">
                        <li>Water Adventure: Seadoo Safari, jetski, rafting, etc</li>
                        <li>Land Adventure: offroad, camping, trekking, etc</li>
                        <li>Sky Adventure: paragliding, etc</li>
                        </ol>
                    </div>`,
            },
            {
              show: false,
              question: "How do I make a booking?",
              answer: `<ol class="list-disc ml-20 text-base font-normal">
                        <li>
                        <i>Login</i> to your Tripwe account or if you don't have an account,
                        please sign up first.
                        </li>
                        <li>
                        On Tripwe home page, choose one of the three menus (Water, Land or Sky
                        Adventure.)
                        </li>
                        <li>Browse the menu and choose a package</li>
                        <li>
                        Or you can also search for specific trips or packages on the Search
                        feature or the Search bar on the Tripwe home page.
                        </li>
                        <li>Click Book on the Package Details page.</li>
                        <li>You will be directed to payment and finish the booking.</li>
                    </ol>`,
            },
            {
              show: false,
              question: "How do I book a Seadoo Safari trip?",
              answer: `<ol class="list-disc ml-20 text-base font-normal">
                        <li> <i>Login</i> to a Tripwe account or if you don't have a Tripwe account, please sign up first.</li>
                        <li>On the Tripwe home page, click the Seadoo Safari menu. </li>
                        <li>Specify the dock location, date and number of jet ski units click Search. Or you can use the Search feature on the Tripwe home page.</li>
                        <li>Select the Seadoo Safari package you want from the search results to display Order Details, Total Price and Map information (to see the Seadoo Safari location)</li>
                        <li>On the Seadoo Safari package details page, you can click Book to make an order.</li>
                    </ol>`,
            },
            {
              show: false,
              question: "Can I order 2 different packages on the same day?",
              answer: `<p class="ml-10 text-base font-normal">Of course you can, Trippers! While stock is available</p>`,
            },
            {
              show: false,
              question: "Which cities does Tripwe reach?",
              answer: `<p class="ml-10 text-base font-normal">Currently, Tripwe is collaborating with several sport adventures in several cities in Indonesia spread across several provinces such as DKI Jakarta, West Java, Central Java, East Java, Makassar, Balikpapan, etc. For more details, please check on the Tripwe app.</p>`,
            },
            {
              show: false,
              question: "Can I change my order?",
              answer: `<p class="ml-10 text-base font-normal">At this time you cannot change a paid order on Tripwe.</p>`,
            },
          ],
        },
        {
          title: "HOW TO PAY: TRIPWEPAY, TRANSFER, CREDIT CARD",
          data: [
            {
              show: false,
              question: "What Attracions are available on Tripwe?",
              answer: `<div class="flex flex-col ml-10">
                    <p class="text-base font-normal">
                        To pay for packages on Tripwe with each payment method, you can see the
                        details here:
                    </p>
                    <p class="text-base font-normal">
                        If you want to pay using TRIPWEPAY, follow these steps:
                    </p>
                    <ol class="list-decimal ml-10 text-base font-normal">
                        <li>Complete your order and process it to the payment step.</li>
                        <li>Choose TripwePay as the payment method</li>
                        <li>Enter your TripwePay PIN (6 Digits).</li>
                        <li>
                        If the payment is successful, then you will get a notification on
                        WhatsApp.
                        </li>
                    </ol>
                    <p class="text-base font-normal">If you want to make a payment using WIRE TRANSFER:</p>
                    <ol class="list-decimal ml-10 text-base font-normal">
                        <li>Complete your order and process it to the payment step.</li>
                        <li>Choose Bank Transfer payment method</li>
                        <li>
                        Information on the payment page will appear such as: Total Payment,
                        Unique Code and Remaining Payment Time.
                        </li>
                        <li>
                        Make a bank transfer via ATM or M-banking to BNI bank virtual account
                        listed on the payment page. (Don't forget, transfer according to the
                        number of orders that have been added with a unique code.)
                        </li>
                        <li>
                        If the payment is successful, then you will get a notification on
                        WhatsApp.
                        </li>
                    </ol>
                    <p class="text-base font-normal">If you want to make a payment using CREDIT CARD:</p>
                    <ol class="list-decimal ml-10 text-base font-normal">
                        <li>Complete your order and process it to the payment step.</li>
                        <li>Choose Credit Card as the payment method.</li>
                        <li>Make sure your email on your profile account is filled.</li>
                        <li>On the payment page, the Total Payment information will appear</li>
                        <li>
                        Complete the Name (according to the name on the Credit Card), Card
                        Number, CVV Number and Card Expiration Date.
                        </li>
                        <li>Click Process Payment.</li>
                        <li>Follow the payment flow according to your bank.</li>
                    </ol>
                    </div>`,
            },
            {
              show: false,
              question:
                "What should I do if the payment info page has not copied the virtual account number and the nominal amount of the payment?",
              answer: `<ol class="list-disc ml-20 text-base font-normal">
                        <li>Click Invoice</li>
                        <li>Click Unpaid and then select according to the order category</li>
                        <li>Choose  the booking</li>
                        <li>Click Order Details</li>
                    </ol>`,
            },
          ],
        },
        {
          title: "HOW TO CONFIRM AN ORDER",
          data: [
            {
              show: false,
              question: "What do I need to do to confirm my order?",
              answer: `<div class="flex flex-col ml-10">
                    <p class="text-base font-normal">
                        You do not need to confirm the order manually because Tripwe has confirmed it through the system. However, if there is something else that needs to be confirmed, the merchant will contact the user directly.
                    </p>
                    </div>`,
            },
            {
              show: false,
              question: "I have completed the payment, what should I do next?",
              answer: `<div class="flex flex-col ml-10">
                        <p class="text-base font-normal">
                            Come to the meeting location on the date and time listed on the invoice. Then, show the order QR code to the adventure operator on duty.
                        </p>
                    </div>`,
            },
            {
              show: false,
              question: "If my order fails, will my balance be refunded?",
              answer: `<div class="flex flex-col ml-10">
                        <p class="text-base font-normal">
                            If you have paid an order using TripwePay or any other payment methods but failed, your money will be returned to your TripwePay account.
                        </p>
                    </div>`,
            },
            {
              show: false,
              question: "How do I view my order history on Tripwe?",
              answer: `<div class="flex flex-col ml-10">
                        <p class="text-base font-normal">
                            The entire order history is in the invoice section of the menu.
                        </p>
                    </div>`,
            },
          ],
        },
        {
          title: "USE VOUCHERS",
          data: [
            {
              show: false,
              question: "How do I use the voucher?",
              answer: `<div class="flex flex-col ml-10">
                    <p class="text-base font-normal">
                        There are 2 ways, namely:
                    </p>
                    <ol class="list-decimal ml-10 text-base font-normal">
                        <li>Click the selected voucher in the My Voucher menu and select the package, then checkout.</li>
                        <li>On the payment page, enter the voucher code or select the available voucher, then checkout.</li>
                    </ol>
                    </div>`,
            },
            {
              show: false,
              question: "Can I use more than one voucher?",
              answer: `<div class="flex flex-col ml-10">
                        <p class="text-base font-normal">
                            For now, 1 transaction can only use 1 voucher.
                        </p>
                    </div>`,
            },
            {
              show: false,
              question: "How do I get a voucher?",
              answer: `<div class="flex flex-col ml-10">
                        <p class="text-base font-normal">
                            Vouchers can be obtained by purchasing vouchers which will be available in the near future on the Tripwe app.
                        </p>
                    </div>`,
            },
            {
              show: false,
              question: "Can the Voucher I get be redeemed?",
              answer: `<div class="flex flex-col ml-10">
                        <p class="text-base font-normal">
                            Vouchers in the Tripwe application cannot be cashed.
                        </p>
                    </div>`,
            },
            {
              show: false,
              question:
                "How do I find out the stock of the package I want to buy?",
              answer: `<div class="flex flex-col ml-10">
                        <p class="text-base font-normal">
                            The package stock can be found on the booking page, when you want to determine the booking date.
                        </p>
                    </div>`,
            },
            {
              show: false,
              question: "How do I view the vouchers I have?",
              answer: `<div class="flex flex-col ml-10">
                        <p class="text-base font-normal">
                            All active vouchers are in the My Voucher menu.
                        </p>
                    </div>`,
            },
          ],
        },
      ],
    };
  },
  created() {
    if (this.$route.params.id == 0) {
      if (this.$route.params.question == 0) {
        this.openAccordionWithUrl(0, 0);
      } else if (this.$route.params.question == 1) {
        this.openAccordionWithUrl(0, 1);
      } else if (this.$route.params.question == 2) {
        this.openAccordionWithUrl(0, 2);
      } else if (this.$route.params.question == 3) {
        this.openAccordionWithUrl(0, 3);
      } else if (this.$route.params.question == 4) {
        this.openAccordionWithUrl(0, 4);
      } else if (this.$route.params.question == 5) {
        this.openAccordionWithUrl(0, 5);
      }
    } else if (this.$route.params.id == 1) {
      if (this.$route.params.question == 0) {
        this.openAccordionWithUrl(1, 0);
      } else if (this.$route.params.question == 1) {
        this.openAccordionWithUrl(1, 1);
      } 
    } else if (this.$route.params.id == 2) {
      if (this.$route.params.question == 0) {
        this.openAccordionWithUrl(2, 0);
      } else if (this.$route.params.question == 1) {
        this.openAccordionWithUrl(2, 1);
      } else if (this.$route.params.question == 2) {
        this.openAccordionWithUrl(2, 2);
      } else if (this.$route.params.question == 3) {
        this.openAccordionWithUrl(2, 3);
      }
    } else if (this.$route.params.id ==3) {
      if (this.$route.params.question == 0) {
        this.openAccordionWithUrl(3, 0);
      } else if (this.$route.params.question == 1) {
        this.openAccordionWithUrl(3, 1);
      } else if (this.$route.params.question == 2) {
        this.openAccordionWithUrl(3, 2);
      } else if (this.$route.params.question == 3) {
        this.openAccordionWithUrl(3, 3);
      } else if (this.$route.params.question == 4) {
        this.openAccordionWithUrl(3, 4);
      } else if (this.$route.params.question == 5) {
        this.openAccordionWithUrl(3, 5);
      }
    }
  },
  methods: {
    toggleQuestion(index, dataIndex) {
      this.FAQs[index].data[dataIndex].show =
        !this.FAQs[index].data[dataIndex].show;
    },
    openAccordionWithUrl(index, dataIndex) {
      this.FAQs[index].data[dataIndex].show = true;
    },
  },
};
</script>
